import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Footer from '../new/components/Footer';
import { CategoriesContextProvider } from '../old/storyblok/categories-context';
import { TagsContextProvider } from '../old/storyblok/tags-context';
import {
  categoriesTransfomer,
  tagsTransformer,
} from '../../scripts/page-data-transformers';

const footerConfig = graphql`
  query Footer {
    footerData: allStoryblokEntry(
      filter: { field_component: { eq: "footer" } }
    ) {
      nodes {
        content
        uuid
      }
    }
    tagData: allStoryblokEntry(
      filter: {
        full_slug: { glob: "tags/**" }
        field_component: { eq: "tagCollectionPage" }
      }
    ) {
      nodes {
        full_slug
        content
        uuid
        slug
      }
    }
    categoriesData: allStoryblokEntry(
      filter: {
        full_slug: { glob: "categories/**" }
        field_component: { eq: "categoryCollectionPage" }
      }
    ) {
      nodes {
        full_slug
        content
        uuid
        slug
      }
    }
  }
`;

const FooterWrap: FC = () => {
  const data: GatsbyTypes.FooterQuery = useStaticQuery(footerConfig);

  const footerNode = data?.footerData?.nodes?.[0];

  const footerData =
    footerNode && footerNode.content ? JSON.parse(footerNode.content) : {};

  const parsedTags = tagsTransformer(data.tagData.nodes);

  const parsedCategories = categoriesTransfomer(data.categoriesData.nodes);

  return (
    <CategoriesContextProvider data={parsedCategories}>
      <TagsContextProvider data={parsedTags}>
        <Footer {...footerData} />
      </TagsContextProvider>
    </CategoriesContextProvider>
  );
};

export default FooterWrap;
