import * as Dialog from '@radix-ui/react-dialog';
import styled from 'styled-components';
import { breakpointUp } from '../../../../styles/mediaUtils';
import { typeScale } from '../../../../styles/newTypography';
import CTAButton from '../../CallToActionLink';
import {
  greyScale,
  spacing,
} from '../../../../styles/newThemeVariables.styles';

export const StyledBurger = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  appearance: none;
  cursor: pointer;
  padding: 0;
  background: transparent;
  color: ${({ theme }) => theme.fg};
  line-height: 0;
  width: ${spacing.space24};
  height: ${spacing.space24};
  margin: ${spacing.space8};
  position: absolute;
  right: 0;
  top: ${spacing.space56};
  z-index: 2;

  :active {
    background: transparent;
  }

  svg {
    fill: ${({ theme }) => theme.black};
  }

  @media ${breakpointUp.tablet} {
    top: ${spacing.space48};
  }

  @media ${breakpointUp.desktop} {
    display: none;
  }
`;

export const StyledClose = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  appearance: none;
  cursor: pointer;
  padding: 0;
  background: transparent;
  color: ${({ theme }) => theme.fg};
  line-height: 0;
  width: ${spacing.space24};
  height: ${spacing.space24};
  margin: ${spacing.space8};
  position: absolute;
  right: ${spacing.space16};
  top: ${spacing.space36};
  z-index: 2;

  :active {
    background: transparent;
  }

  svg {
    fill: ${({ theme }) => theme.black};
  }

  @media ${breakpointUp.tablet} {
    right: ${spacing.space24};
    top: ${spacing.space24};
  }

  @media ${breakpointUp.desktop} {
    display: none;
  }
`;

export const Overlay = styled(Dialog.Overlay)`
  position: fixed;
  inset: 0;
  background-color: ${greyScale.black15};
  backdrop-filter: blur(0.9375rem);

  @media ${breakpointUp.sm} {
    background-color: ${greyScale.black25};
  }
`;

export const Content = styled(Dialog.Content)`
  background-color: ${({ theme }) => theme.bg};
  color: ${({ theme }) => theme.fg};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  margin: 0;
  overflow: auto;
  z-index: 500;
`;

export const ContentWrapper = styled.div`
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  gap: ${spacing.space16};
  padding: ${spacing.space120} 0;

  @media ${breakpointUp.sm} {
    gap: ${spacing.space24};
    grid-column: 1 / -1;
  }
`;

export const StyledMainList = styled.ul`
  list-style: none;
  margin: 0;
  padding: ${spacing.space48} 0;
  ${typeScale.mobile.body.xl};
  grid-column: span 2;

  li {
    width: 100%;
    margin-bottom: ${spacing.space16};

    :last-child {
      margin-bottom: 0;
    }

    a {
      display: inline-block;
      width: 100%;
      color: ${({ theme }) => theme.textPrimary};
    }
  }
`;

export const StyledCTAButton = styled(CTAButton)`
  align-self: flex-start;
`;
