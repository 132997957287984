import React from 'react';
import { createContext, ReactNode, useContext } from 'react';

export type CategoryType = {
  title: string;
  uuid: string;
  full_slug: string;
  slug: string;
  order?: string;
};

interface CategoriesContextShape extends Array<CategoryType> {}

interface CategoriesContextProviderProps {
  children: ReactNode;
  data: CategoryType[];
}

const CategoriesContext = createContext<CategoriesContextShape | undefined>(
  undefined
);

export const CategoriesContextProvider = ({
  children,
  data,
}: CategoriesContextProviderProps): JSX.Element => {
  return (
    <CategoriesContext.Provider value={data}>
      {children}
    </CategoriesContext.Provider>
  );
};

export const useCategories = () => {
  const categoriesContext = useContext(CategoriesContext);
  if (categoriesContext === undefined) {
    throw new Error('Missing passed allCategories from pageContext');
  }

  return categoriesContext;
};
