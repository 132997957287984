import type { YouTubePlayer } from 'youtube-player/dist/types';
import { Dispatch, useEffect, useState } from 'react';

type VideoPlayer = YouTubePlayer;
type VideoListener = Dispatch<VideoPlayer[]>;

const currentVideos = new Set<VideoPlayer>();
const listeners = new Set<VideoListener>();

export function registerVideoPlayer(player: VideoPlayer): void {
  currentVideos.add(player);
  listeners.forEach((listener) => listener(Array.from(currentVideos)));
}

export function unregisterVideoPlayer(player: VideoPlayer): void {
  currentVideos.delete(player);
  listeners.forEach((listener) => listener(Array.from(currentVideos)));
}

export default function useVideoPlayer(): VideoPlayer[] {
  const [players, setPlayers] = useState(Array.from(currentVideos));
  useEffect(() => {
    listeners.add(setPlayers);
    return () => {
      listeners.delete(setPlayers);
    };
  }, [setPlayers]);
  return players;
}
