import React, { FC } from 'react';
import styled from 'styled-components';

const ImageWrap = styled.div`
  && ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  && li {
    flex: 0 1 45%;
    margin: 0 8px;
    padding: 0;
  }
`;

const PostImageGroup: FC = ({ children }) => {
  return <ImageWrap>{children}</ImageWrap>;
};

export default PostImageGroup;
