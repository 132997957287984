import React from 'react';
import { ThemeProvider } from 'styled-components';
import PageHeader from './style-components/PageHeader';
import GlobalStyles from '../../styles/globalStyles';
import lightTheme from '../../styles/themeVariables';

type State = {
  hasError: boolean;
};

export default class ErrorBoundary extends React.Component<unknown, State> {
  constructor(props: unknown) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(): void {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render(): React.ReactNode {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <ThemeProvider theme={lightTheme}>
          <main role="main">
            <GlobalStyles />
            <PageHeader
              bgColor="#fff"
              text={[
                'There was a problem',
                'Something went wrong. The page is designed for modern browsers, if ' +
                  'you are viewing the page in an older browser please try updating it ' +
                  'to the latest version.',
              ]}
            />
          </main>
        </ThemeProvider>
      );
    }
    return children;
  }
}
