import React, { FC, useCallback, useState } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { PageProps } from 'gatsby';

import IsPageTransitioning from './context';

type Props = {
  location: PageProps['location'];
};

const RoutesTransitionGroup: FC<Props> = ({ children, location }) => {
  const [isPageTransitioning, setPageTransitioning] = useState(false);

  const handleTransitionStart = useCallback(
    () => setPageTransitioning(true),
    []
  );

  const handleTransitionComplete = useCallback(
    () => setPageTransitioning(false),
    []
  );

  return (
    <TransitionGroup exit={false}>
      <CSSTransition
        key={location.key}
        classNames="routetransition"
        timeout={450}
        onEnter={handleTransitionStart}
        onEntered={handleTransitionComplete}
      >
        <IsPageTransitioning.Provider value={isPageTransitioning}>
          {children}
        </IsPageTransitioning.Provider>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default RoutesTransitionGroup;
