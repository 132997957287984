import styled, { css, keyframes } from 'styled-components';
import React, { FC } from 'react';
import { breakpointUp } from '../../../styles/mediaUtils';

const slideIn = keyframes`
  from {
    transform: translateX(-1.875rem);
  }

  to {
    transform: translateX(-0);
  }
`;
const fadeIn = keyframes`
  from {
    opacity: 0.1;
  }

  to {
    opacity: 1;
  }
`;

const SlideInHeaderItem = styled.div<{ addedDelay?: number }>`
  @media (${breakpointUp.animationBreakpoint}) {
    ${({ addedDelay = 0 }) => css`
      animation: ${slideIn} 400ms ease-out ${addedDelay}ms,
        ${fadeIn} 400ms ease-in ${addedDelay}ms;
    `};
    animation-fill-mode: both;
  }
`;

type Props = { className?: string; addedDelay?: number };

const TransitionSlideInHeaderItem: FC<Props> = ({
  children,
  className,
  addedDelay,
}) => (
  <SlideInHeaderItem className={className} addedDelay={addedDelay}>
    {children}
  </SlideInHeaderItem>
);

export default TransitionSlideInHeaderItem;
