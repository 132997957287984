import React, { FC, useEffect, useRef } from 'react';
import youTubePlayer from 'youtube-player';
import styled from 'styled-components';
import { registerVideoPlayer, unregisterVideoPlayer } from '../useVideoPlayer';

const StyledVideoContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;

  & > iframe {
    position: absolute;
    left: 0;
    top: 0;
  }
`;

type Props = {
  className?: string;
  url: string;
};

const YoutubeVideo: FC<Props> = ({ className, url }) => {
  const youTubeID = /v=([^&]+)/.exec(url);
  const ref = useRef<HTMLDivElement>(null);
  const initialID = useRef<string>(youTubeID ? youTubeID[1] : '');

  useEffect(() => {
    if (ref.current) {
      const player = youTubePlayer(ref.current, {
        width: '100%',
        height: '100%',
        videoId: initialID.current,
      });
      registerVideoPlayer(player);
      return () => {
        unregisterVideoPlayer(player);
      };
    }
    return () => {};
  }, []);

  return (
    <StyledVideoContainer className={className}>
      <div ref={ref} />
    </StyledVideoContainer>
  );
};

export default React.memo(YoutubeVideo);
