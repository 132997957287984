import styled, { keyframes } from 'styled-components';
import React, { FC } from 'react';
import { breakpointUp } from '../../../styles/mediaUtils';
import SlideInHeaderItem from '../transition-components/SlideInHeaderItem';
import HeaderText from '../HeaderText';
import { Layout } from '../../../new/layout/Layout';

const slideUpAnimation = keyframes`
  from {
    margin-bottom: 2rem;
  }

  to {
    margin-bottom: 0;
  }
`;

const Header = styled.header<{ hasChildren: boolean }>`
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${(props) => (props.hasChildren ? 'auto auto' : 'auto')};
  column-gap: ${({ theme }) => theme.siteOuterMargin};
  justify-items: stretch;
  align-items: ${(props) => (props.hasChildren ? 'center' : 'end')};

  @media ${breakpointUp.md} {
    grid-template-columns: ${(props) =>
      props.hasChildren ? '1fr 1fr' : '1fr'};
    grid-template-rows: auto auto;
    column-gap: ${({ theme }) => theme.siteOuterMarginLg};
  }
`;

export const HeaderContent = styled.div`
  display: grid;
  align-items: end;
  justify-items: stretch;
  min-height: 65vh;
  padding: 10.5rem 0 3rem 0;

  @media ${breakpointUp.lg} {
    padding: 10.5rem 0 6rem 0;
  }
`;

const BGWrap = styled.div<{ bgColor?: string }>`
  position: relative;
  color: ${({ theme }) => theme.fg};
  background-color: ${({ bgColor, theme }) => bgColor || theme.bg};
  overflow: hidden;

  @media (${breakpointUp.animationBreakpoint}) {
    animation: ${slideUpAnimation} 1000ms ease-in-out;
  }
`;

const BGImage = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;

  .gatsby-image-wrapper {
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

type Props = {
  bgColor?: string;
  className?: string;
  text?: Parameters<typeof HeaderText>[0]['text'];
  bgImage?: React.ReactNode;
  content?: React.ReactNode;
};

const PageHeader: FC<Props> = ({
  className,
  text,
  content,
  bgColor,
  bgImage,
  children,
}) => {
  return (
    <BGWrap bgColor={bgColor}>
      {bgImage && <BGImage>{bgImage}</BGImage>}
      <Layout>
        <HeaderContent className={className}>
          <SlideInHeaderItem>
            <Header hasChildren={!!children}>
              {content || !text ? (
                <div>{content}</div>
              ) : (
                <HeaderText text={text} />
              )}
              {children && <div>{children}</div>}
            </Header>
          </SlideInHeaderItem>
        </HeaderContent>
      </Layout>
    </BGWrap>
  );
};

export default PageHeader;
