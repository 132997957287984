import React, { FC } from 'react';
import styled from 'styled-components';

const StyledPara = styled.aside`
  & > p {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #53585f;
    opacity: 60%;
    margin-top: 0;
  }
`;

const SmallPara: FC = ({ children }) => {
  return <StyledPara>{children}</StyledPara>;
};

export default SmallPara;
