exports.contentAuthorsTransformer = (data) => {
  return data.map((item) => {
    const content = JSON.parse(item.content);
    return {
      slug: item.slug,
      name: content.display_name,
      description: content.description,
      avatar: content.avatar,
      linkedin: content.linkedin,
      twitter: content.twitter,
      facebook: content.facebook,
      email: content.email,
      uuid: item.uuid,
    };
  });
};

exports.categoriesTransfomer = (data) => {
  return data
    .map((item) => {
      const content = JSON.parse(item.content);
      return {
        full_slug: item.full_slug,
        title: content.display_name,
        uuid: item.uuid,
        slug: item.slug,
        order: content.order,
        metaData: {
          title: content.meta_title,
          description: content.meta_description,
          image: content.meta_image.filename,
        },
      };
    })
    .sort((catA, catB) => catA.order - catB.order);
};

exports.tagsTransformer = (data) => {
  return data
    .map((item) => {
      const content = JSON.parse(item.content);
      return {
        full_slug: item.full_slug,
        title: content.display_name,
        uuid: item.uuid,
        slug: item.slug,
        order: content.order,
        metaData: {
          title: content.meta_title,
          description: content.meta_description,
          image: content.meta_image.filename,
        },
      };
    })
    .sort((tagA, tagB) => tagA.order - tagB.order);
};

exports.whitePaperFormDataTransformer = (data) => {
  const outputData = [];

  data.forEach((item) => {
    if (!!outputData.find((i) => i.id === item.name)) {
      return null;
    }

    const itemDataElements = data.filter((i) => i.name === item.name);

    outputData.push({
      id: item.name,
      name: item.value,
      formKey: itemDataElements.find(
        (i) => i.data_source_dimension === 'Form Key'
      )?.dimension_value,
      template: itemDataElements.find(
        (i) => i.data_source_dimension === 'Template'
      )?.dimension_value,
      reportName: itemDataElements.find(
        (i) => i.data_source_dimension === 'Report Name'
      )?.dimension_value,
      deckLink: itemDataElements.find(
        (i) => i.data_source_dimension === 'Deck Link'
      )?.dimension_value,
    });
  });

  return outputData;
};

exports.getSimpleMetaData = (JSONContent) => {
  const content = JSON.parse(JSONContent);

  return {
    title: content.meta_title,
    description: content.meta_description,
    image: content.meta_image?.filename,
    no_index: content.no_index,
  };
};

exports.contactFormDataTransformer = (data) => {
  const outputData = [];

  data.forEach((item) => {
    if (!!outputData.find((i) => i.id === item.name)) {
      return null;
    }

    const itemDataElements = data.filter((i) => i.name === item.name);

    outputData.push({
      id: item?.name ?? '',
      name: item?.value ?? '',
      formKey:
        itemDataElements?.find((i) => i.data_source_dimension === 'Form Key')
          ?.dimension_value ?? '',
    });
  });

  return outputData;
};
