export interface StoryblokLinkObject {
  id: string;
  url: string;
  linktype: string;
  fieldtype: string;
  cached_url: string;
}

export const resolveInternalLink = (link: StoryblokLinkObject) => {
  if (typeof link === 'object' && 'cached_url' in link) {
    const updatedLink = { ...link };
    if (updatedLink.url === '') {
      updatedLink.url = updatedLink.cached_url;
    }
    return updatedLink;
  }
};
