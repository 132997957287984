import React, { FC } from 'react';
import styled from 'styled-components';
import CMSLink from '../CMSLink';
import {
  StoryblokLinkObject,
  resolveInternalLink,
} from '../../../old/storyblok/util/resolveInternalLink';
import { useLocation } from '@gatsbyjs/reach-router';

const Link = styled(CMSLink)`
  display: inline-block;
  position: relative;

  ::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.078125rem;
    bottom: -0.25rem;
    left: 0;
    transform: ${({ isActive }) => (isActive ? `scaleX(1)` : `scaleX(0)`)};
    pointer-events: ${({ isActive }) => (isActive ? 'none' : 'all')};
    cursor: ${({ isActive }) => (isActive ? 'not-allowed' : 'pointer')};
    background-color: ${({ isActive, theme }) =>
      isActive ? theme.cloud : theme.greyMedium};
    transition: transform 0.35s ease-out;
    transform-origin: bottom left;
  }

  &:hover::after,
  &:active::after {
    transform: scaleX(1);
  }
`;

export type NavLinkProps = {
  _uid: string;
  name?: string;
  link: Partial<StoryblokLinkObject>;
  filterUrl?: boolean;
};

const NavLink: FC<NavLinkProps> = ({ name, link, filterUrl = true }) => {
  const normalizedLink = {
    url: '',
    id: '',
    linktype: '',
    fieldtype: '',
    cached_url: '',
    ...link,
  };

  const resolvedLink = resolveInternalLink(normalizedLink);
  const location = useLocation();

  return (
    <Link
      to={resolvedLink?.url || ''}
      isActive={location.pathname?.includes(resolvedLink?.url || '')}
      filterUrl={filterUrl}
    >
      {name}
    </Link>
  );
};

export default NavLink;
