import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import { FC } from 'react';
import styled from 'styled-components';

const duration = 400;

const StyledTransitionWrapper = styled.div<{ delayIndex: number }>`
  transition: opacity ${duration}ms ease-out;
  transition-delay: ${({ delayIndex }) => 250 + 100 * delayIndex}ms;

  &.fade-transition-appear,
  &.fade-transition-enter {
    opacity: 0;
  }

  &.fade-transition-appear-active,
  &.fade-transition-enter-active {
    opacity: 1;
  }

  &.fade-transition-exit {
    opacity: 1;
  }

  &.fade-transition-exit-active {
    opacity: 0;
  }
`;

type StyledTransitionWrapperProps = Omit<
  Parameters<typeof StyledTransitionWrapper>[0],
  'delayIndex'
>;

const FadeInMenuItem: FC<
  {
    delay?: number;
    element?: 'li' | 'span';
  } & StyledTransitionWrapperProps
> = ({ children, delay = 0, element, ...props }) => {
  return (
    <CSSTransition
      timeout={{
        enter: duration,
        exit: 0,
      }}
      in
      classNames="fade-transition"
      appear
      mountOnEnter
      unmountOnExit
    >
      <StyledTransitionWrapper {...props} delayIndex={delay}>
        {children}
      </StyledTransitionWrapper>
    </CSSTransition>
  );
};

export default FadeInMenuItem;
