import React, { FC, useEffect, useState } from 'react';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import lightTheme, { darkTheme } from '../../../styles/themeVariables';
import {
  BAROMETER_CASESTUDY_ROUTE,
  BLOG_POST_PAGE_MATCH,
  BUILD_A_BANK_ROUTE,
  BUPA_CASESTUDY_ROUTE,
  BUSINESS_IMPACT_DESIGN_ROUTE,
  FOUNDERS_CASESTUDY_ROUTE,
  FRAME_CASESTUDY_ROUTE,
  GO_COMPARE_CASESTUDY_ROUTE,
  GOOGLE_RETAIL_CASESTUDY_ROUTE,
  HOPSTER_CASESTUDY_ROUTE,
  INMARSAT_CASESTUDY_ROUTE,
  JAMESON_CASESTUDY_ROUTE,
  KINDEO_CASESTUDY_ROUTE,
  MYPENSION_CASESTUDY_ROUTE,
  QUIT_GENIUS_CASESTUDY_ROUTE,
  SUZY_SNOOZE_CASESTUDY_ROUTE,
  VIDEO_POST_PAGE_MATCH,
  ZEGO_CASESTUDY_ROUTE,
} from '../../../routes';

export type LocationData = { pathname: string };
type Props = {
  location: LocationData;
};

// theme is based on background color which doesn't exist on the nav, so renaming here for clarity
const darkText = lightTheme;
const lightText = darkTheme;

const lightPaths = [
  BLOG_POST_PAGE_MATCH,
  VIDEO_POST_PAGE_MATCH,
  BUPA_CASESTUDY_ROUTE,
  FRAME_CASESTUDY_ROUTE,
  HOPSTER_CASESTUDY_ROUTE,
  BAROMETER_CASESTUDY_ROUTE,
  QUIT_GENIUS_CASESTUDY_ROUTE,
  SUZY_SNOOZE_CASESTUDY_ROUTE,
  KINDEO_CASESTUDY_ROUTE,
  JAMESON_CASESTUDY_ROUTE,
  GO_COMPARE_CASESTUDY_ROUTE,
  GOOGLE_RETAIL_CASESTUDY_ROUTE,
  INMARSAT_CASESTUDY_ROUTE,
  MYPENSION_CASESTUDY_ROUTE,
  ZEGO_CASESTUDY_ROUTE,
  BUILD_A_BANK_ROUTE,
  FOUNDERS_CASESTUDY_ROUTE,
  BUSINESS_IMPACT_DESIGN_ROUTE,
];

function getNavTheme(location: LocationData) {
  /* Remove trailing slash if there is one */
  const pathname = location.pathname.replace(/\/$/, '');

  const useLightText = lightPaths.some((matcher) => {
    if (typeof matcher === 'function') {
      return matcher(pathname);
    }
    return matcher === pathname;
  });

  if (useLightText) {
    return lightText;
  }
  return darkText;
}

const currentTheme: DefaultTheme | null = null;
const listeners: React.Dispatch<React.SetStateAction<DefaultTheme | null>>[] =
  [];

export const useNavTheme = (theme: DefaultTheme): void => {
  useEffect(() => {
    listeners.forEach((listener) => listener(theme));
    return () => {
      listeners.forEach((listener) => listener(null));
    };
  }, [theme]);
};

const NavTheme: FC<Props> = ({ location, children }) => {
  const [forcedTheme, setForcedTheme] = useState<DefaultTheme | null>(
    currentTheme
  );
  const theme = getNavTheme(location);

  useEffect(() => {
    listeners.push(setForcedTheme);
    return () => {
      listeners.splice(listeners.indexOf(setForcedTheme), 1);
    };
  }, [setForcedTheme]);

  return <ThemeProvider theme={forcedTheme || theme}>{children}</ThemeProvider>;
};

export default NavTheme;
