import { css } from 'styled-components';
import theme from './themeVariables';

// idea from https://www.styled-components.com/docs/advanced#media-templates

type Breakpoints = keyof typeof theme.breakpoints;

const BreakpointKeys = Object.keys(theme.breakpoints) as Breakpoints[];

export const breakpointUp = BreakpointKeys.reduce((acc, label) => {
  acc[label] = () => `
    (width > ${theme.breakpoints[label]})
  `;

  return acc;
}, {} as { [breakpoint in Breakpoints]: () => string });

export const breakpointUpStyle = BreakpointKeys.reduce((acc, label) => {
  acc[label] = (...args: Parameters<typeof css>) => css`
    @media (width > ${theme.breakpoints[label]}) {
      ${css(...args)};
    }
  `;

  return acc;
}, {} as { [breakpoint in Breakpoints]: (...args: Parameters<typeof css>) => ReturnType<typeof css> });
