import React, { FC, useMemo } from 'react';
import styled from 'styled-components';

const HeaderTextContainer = styled.div`
  position: relative;
  max-width: ${({ theme }) => theme.maxContainerWidthLg};

  h1 {
    max-width: 41rem;
    font-size: 32px;
    line-height: 50px;
    letter-spacing: -0.38px;
  }

  p {
    max-width: 35rem;
    font-size: 19px;
    line-height: 32px;
    color: ${({ theme }) => theme.fgFaint};
  }
`;

const Paragraph = styled.p<{ $maxWidth: number }>`
  && {
    max-width: ${(props) => props.$maxWidth};
  }
`;

export interface HeaderTextBlock {
  text: string;
  as?: Parameters<typeof Paragraph>[0]['as'];
  maxWidth?: string;
}

type Props = {
  text: string | HeaderTextBlock | (string | HeaderTextBlock)[];
};

const decorateHeadingBlock = (
  text: string | HeaderTextBlock | (string | HeaderTextBlock)[]
): HeaderTextBlock[] => {
  const arrayedText = text instanceof Array ? text : [text];
  return arrayedText.map((block) => {
    if (typeof block === 'string') {
      return { text: block };
    }
    return block;
  });
};

const HeaderText: FC<Props> = ({ text }) => {
  const sourceText = useMemo(() => decorateHeadingBlock(text), [text]);

  return (
    <HeaderTextContainer>
      {sourceText.map((content, i) => (
        <Paragraph
          as={content.as || (i === 0 ? 'h1' : 'p')}
          key={content.text}
          $maxWidth={content.maxWidth}
        >
          {content.text}
        </Paragraph>
      ))}
    </HeaderTextContainer>
  );
};

export default HeaderText;
