/* eslint-disable react/jsx-filename-extension */

import React from 'react';
import ReactGA from 'react-ga';
import PageWrap from './src/old/components/PageWrap';

export const onRouteUpdate = ({ location, prevLocation }) => {
  const hsq = (window._hsq = window._hsq || []);
  hsq.push(['setPath', location.pathname + location.search]);
  if (!prevLocation) {
    return;
  }
  ReactGA.pageview(location.pathname);
  hsq.push(['trackPageView']);
};

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <PageWrap {...props}>{element}</PageWrap>;
};
